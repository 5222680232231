import React from "react";

import * as classes from "./QuantityAdjuster.module.css";

export function QuantityAdjuster({ item, onAdjust }) {
  const { quantity } = item;

  const handleDecrementQty = () => {
    onAdjust({ variantId: item.variant.id, quantity: -1 });
  };

  const handleIncrementQty = () => {
    onAdjust({ variantId: item.variant.id, quantity: 1 });
  };

  return (
    <div className={classes.quantityAdjuster}>
      <label htmlFor="quantity">Qty: </label>
      <span className={classes.quantity}>{quantity}</span>
      <button onClick={handleDecrementQty}>-</button>
      <button onClick={handleIncrementQty}>+</button>
    </div>
  );
}
