import React from "react";
import Cart from "../components/Cart/Cart";
import Seo from "../components/Seo/seo";

const CartPage = () => {
  return (
    <>
      <Seo title="Cart" />
      <Cart />
    </>
  );
};

export default CartPage;
