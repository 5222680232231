import React, { useContext } from "react";

import CartContext from "../../context/CartContext";

import { QuantityAdjuster } from "./QuantityAdjuster";
// import { RemoveLineItem } from "./RemoveLineItem";

import * as classes from "./Cart.module.css";

const Cart = () => {
  const { checkout, updateLineItem } = useContext(CartContext);

  const handleAdjustQuantity = ({ quantity, variantId }) => {
    updateLineItem({ quantity, variantId });
  };

  return (
    <section className={classes.cart}>
      <div className={classes.introContainer}>
        <span className={classes.preTitle}>Goriot</span>
        <h1 className={classes.productTitle}>Order Details</h1>
        <h2 className={classes.subTitle}>Shipping</h2>
      </div>
      {checkout?.lineItems?.map((item) => (
        <div className={classes.lineItem} key={item.variant.id}>
          <img
            className={classes.image}
            src={item.variant.image.src}
            alt={item.title}
          />

          <div className={classes.title}>{item.title}</div>
          <div className={classes.price}>
            ${item.variant.price}
            {/* {checkout.currencyCode} */}
          </div>
          <div className={classes.variant}>
            <span className={classes.sizeLabel}>Size:</span>
            {item.variant.title === "Default Title" ? "" : item.variant.title}
          </div>
          <div className={classes.quantity}>
            <QuantityAdjuster item={item} onAdjust={handleAdjustQuantity} />
            {/* <div>£{(item.quantity * item.variant.price).toFixed(2)}</div> */}
          </div>
          {/* <div>
            <RemoveLineItem lineItemId={item.id} />
          </div> */}
        </div>
      ))}
      {!!checkout?.lineItems ? (
        <div className={classes.subtotal}>
          <span className={classes.subtotalLabel}>Subtotal: </span>
          <span>
            ${checkout?.totalPrice} ({checkout.currencyCode})
          </span>
        </div>
      ) : (
        <h4>You cart is empty.</h4>
      )}
      <div className={classes.checkout}>
        {!!checkout?.webUrl && (
          <button
            onClick={() => {
              window.location.href = checkout.webUrl;
            }}
          >
            Checkout
          </button>
        )}
      </div>
    </section>
  );
};

export default Cart;
