// extracted by mini-css-extract-plugin
export var cart = "Cart-module--cart--352Af";
export var introContainer = "Cart-module--introContainer--2kM9X";
export var preTitle = "Cart-module--preTitle--26rs_";
export var productTitle = "Cart-module--productTitle--221cN";
export var subTitle = "Cart-module--subTitle--1QoQd";
export var lineItem = "Cart-module--lineItem--7snIy";
export var image = "Cart-module--image--c9RMA";
export var title = "Cart-module--title--3ANY5";
export var price = "Cart-module--price--1ysMp";
export var sizeLabel = "Cart-module--sizeLabel--3PPrJ";
export var variant = "Cart-module--variant--5Br4P";
export var quantity = "Cart-module--quantity--3aXhr";
export var subtotal = "Cart-module--subtotal--11EJw";
export var subtotalLabel = "Cart-module--subtotalLabel--25fN3";
export var checkout = "Cart-module--checkout--1GsOp";
export var mainContainer = "Cart-module--mainContainer--3w52S";